.infoHolder {
  width: 70%;
  margin: 0 auto;

  /* margin-top: 2em; */
}
.addDiv {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.missingbtn {
  color: #fff;
  border-radius: 8px;
  background: #f07167;
  border: unset;
  padding: 1em 2em;
  width: 100%;
  cursor: pointer;
}
.addbtn {
  color: #fff;
  border-radius: 8px;
  background: #4bd3c5;
  border: unset;
  padding: 1em 2em;
  width: 100%;
  cursor: pointer;
}
.infoHolder h1 {
  font-weight: 600;
}

.infoaccordionbg {
  position: relative;
  background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%);
  border-radius: 15px;
  padding-top: 15px;
  width: 100%;
  min-height: 48vh;
}
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="date"] {
  font-family: "Poppins", sans-serif !important;
}
.wallet {
  display: flex;
  flex-direction: row;
}
.appleimg {
  margin-right: 10px;
}

.infoaccSummary {
  background: #fff;
  border-radius: 15px;
  box-shadow: 3px 4px 13px lightgrey;
  min-height: 48vh;
  margin-bottom: 20px;
  padding-bottom: 50px;
}
label {
  font-family: "Poppins", sans-serif !important;
}

.welcometext {
  /* margin: 040px; */
  margin-top: 2em !important;
}
.MuiTypography-body1 {
  font-size: 15px !important;
}
.MuiCollapse-wrapper {
  box-shadow: 0px 4px 9px rgb(0 0 0 / 25%);
  border-radius: 15px;
}
.gotcss {
  font-size: 20px;
}
.gotquestions {
  display: flex;
  margin-bottom: 20px;
}
.questioncss {
  font-size: 20px;
  /* margin-left: 8px; */
  font-weight: 600;
}
.Infogrid {
  padding-top: 12px;
  padding-left: 18px;
  display: flex;
  justify-content: space-between;
}
.MuiFormLabel-root {
  color: gray !important;
}
.buttonNext {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.secCompany {
  color: #00afb9;
  margin: 0em;
}

.buttonNext button {
  background: #f07167;
  border-radius: 16px;
  border-radius: 8px;
  padding: 0.5em 1em;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  float: left;
}

.loaderAdj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit .btn {
  background: #4bd3c5;
  border-radius: 16px;
  border-radius: 15px;
  /* padding: 0.8em 6em; */
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 15%;
  /* margin-right: 36px; */
  /* height: 47px; */
}
.submit .btn1 {
  background: #4bd3c5;
  border-radius: 16px;
  border-radius: 15px;
  /* padding: 0.8em 6em; */
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 15%;
}
.submit .btn:active{
  transform: scale(0.95);
  box-shadow: 1px 2px 2px 1px rgb(0 0 0 / 24%);
}
.submit {
  display: flex;
  justify-content: center;
}

.captcha {
  margin: 2em 0;
  display: flex;
  justify-content: center;
}

.copyRight {
  color: #00afb9;
}

.adjs {
  font-size: 12px;
}
.dobdropdown {
  margin-top: 10px;
  width: 70%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .infoHolder {
    width: 95%;

    margin: 0 10px 0 10px;
  }
  .dobdropdown {
    width: 95%;
    margin-right: 36px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #monthdropdown,
  #daydropdown,
  #yeardropdown {
    width: 100%;
  }
  .retrivaldobdropdown {
    width: 80%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  #retrivalmonthdropdown,
  #retrivaldaydropdown,
  #retrivalyeardropdown {
    width: 100% !important;
    background-color: #fff !important;
  }
  .wallet {
    display: flex;
    flex-direction: column;
  }

  .submit .btn {
    width: 30%;
    margin-right: 48px;
  }
  .submit .btn1 {
    width: 30%;
    margin-right: 0 !important;
  }
  .submit .btn3 {
    width: 70% !important;
  }
  #seconndoseLocation {
    background-color: pink;
  }
  .loginSubmit {
    width: 40% !important;
  }
  .paperModal {
    position: absolute;
    width: 86%;
    background-color: #fff;
    border-radius: 20px;
  }
  .center img {
    height: 191px;
  }
  .closeHolder img {
    position: absolute;
    top: 9px;
    right: 6px;
    z-index: 11;
    height: 23px;
  }

  .successmsg h1 {
    font-weight: 400;
    font-size: 20px;
  }
  .content {
    background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
    padding: 1em 0;
    border-radius: 20px;
    -webkit-clip-path: polygon(0% 0%, 88% 0%, 100% 28%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 83% 0%, 100% 28%, 100% 100%, 0% 100%);
  }
  option {
    font-family: "Poppins", sans-serif !important;
  }
  .rander img {
    position: absolute;
    height: 100px;
    height: 99px;
    left: 50%;
    transform: translate(-160%, 50%);
  }

  .adjs {
    font-size: 12px;
  }

  .accordionHolder {
    width: 100%;
  }

  .bannerRight {
    position: absolute;
    right: -32px;
    height: 78px;
    top: 54px;
  }

  .bannerHolder h1 {
    margin-top: 0.5em;
    font-size: 1.4em;
  }

  .copyRight {
    text-align: center;
  }
}
.popImage {
  max-height: 120px;
}
.popImage1 {
  max-height: 180px;
}
.MuiCheckbox-root {
  color: #00afb9 !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #00afb9 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #00afb9 !important;
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .accordionHolder {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .adjs {
    font-size: 12px;
  }

  .adjsd {
    font-size: 12px;
  }
}

.privacytext {
  color: grey;
  font-size: 12px;
}
.accordionbgform {
  position: relative;
  background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%);
  border-radius: 15px;
  padding-top: 10px;
  width: 85%;
  min-height: 48vh;
  margin-top: 30px;
}
.formseperator {
  border-bottom: 1px solid gray;
  width: 88%;
  margin-left: 6%;
}
.loginGrid1 {
  padding: 25px;
  cursor: pointer;
}
.correcticon {
  width: 20px;
}
.secCompany {
  color: #00afb9;
}
.MuiFormControlLabel-root {
  margin-right: 14px !important;
}
/* .LoginMethod .MuiFormControlLabel-root {
  margin-right: 100px !important;
} */
.correcticonDiv {
  padding-left: 70px;
}
.Infogrid {
  display: flex;
  justify-content: space-between;
}
.nextbtn {
  background: #4bd3c5;
  border: none;
  padding: 10px;
  width: 90%;
  border-radius: 10px;
  color: white;
  font-size: 18px;
}
.savebtn {
  background: #4bd3c5;
  border: none;
  padding: 10px;
  width: 20%;
  border-radius: 10px;
  color: white;
  font-size: 18px;
}
.pdfbtn {
  margin-top: 40px;
  background: #4bd3c5;
  border: none;
  padding: 15px;
  width: 15vw;
  border-radius: 10px;
  color: white;
  font-size: 18px;
}
.nextbtnDiv {
  text-align: center;
  margin-top: 50px;
}
.accSummaryform {
  background: #fff;
  border-radius: 15px;
  box-shadow: 3px 4px 13px lightgrey;
  min-height: 60vh;
}
.skipbtn {
  color: #4bd3c5;
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
  cursor: pointer;
}
.chaticon {
  width: 80px;
  cursor: pointer;
}
@media only screen and (max-width: 960px) {
  .formTop1 {
    margin-top: 15px !important;
  }

  .dobdropdown {
    width: 95%;
    margin-right: 36px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 150px;
  }
  #monthdropdown,
  #daydropdown,
  #yeardropdown {
    width: 100%;
  }
  .submit .btn {
    width: 30%;
    margin-right: 36px;
  }
}
@media only screen and (max-width: 600px) {
  .mediachange {
    margin-left: 0px !important;
  }
  .walletbtn {
    width: 40vw !important;
  }
  .accordionbgform {
    width: 100% !important;
  }

  .stepper div,
  .stepper a {
    font-size: 14px !important;
  }
}
.react-tel-input .form-control {
  width: 100% !important;
  height: 45px !important;
}
.special-label {
  display: none !important;
}
.formTop1 {
  margin-top: 25px !important;
}
.formTop {
  margin-top: 10px !important;
}
.formTop3 {
  margin: 10px 0 5px 0 !important;
}
.loaderAdj {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}
#daydropdown {
  font-size: 16px;
  background-color: #fff !important;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  margin-right: 10px;
  padding: 0px 0px 15px 0px;
  border: none;
  width: 100%;
  border-bottom: 1px solid #d3d7d8;
  color: gray;
}
#monthdropdown {
  font-size: 16px;
  background-color: #fff !important;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
  padding: 0px 0px 15px 0px;
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid #d3d7d8;
  color: gray;
}

#yeardropdown {
  font-size: 16px;
  background-color: #fff !important;
  width: 100%;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  padding: 0px 0px 15px 0px;
  border: none;
  border-bottom: 1px solid #d3d7d8;
  color: gray;
}

.retrivaldobdropdown {
  width: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
}

#retrivalmonthdropdown,
#retrivaldaydropdown,
#retrivalyeardropdown {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  padding: 0px 0px 15px 0px;
  width: 30%;
  border: none;
  border-bottom: 1px solid #d3d7d8;
  color: gray;
  background-color: #fff !important;
}

.errorText {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: rgb(191, 38, 0);
  margin-top: 10px;
  margin-bottom: -10px;
}
.SuccessText {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #55a055;
  margin-top: 10px;
  margin-bottom: -10px;
  font-weight: 700;
}
.MuiInputBase-root::placeholder {
  color: black !important;
}
.ThankInfo {
  text-align: center;
  padding-top: 13vh;
  font-size: 20px;
}
.Thankyou {
  padding-bottom: 35px;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  position: relative;
  padding: 12px 50px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px dashed #ccc;
  text-align: center;
  background: #f07167;
  color: white;
  cursor: pointer;
}
.custom-file-upload1 {
  position: relative;
  padding: 12px 93px !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px dashed #ccc;
  text-align: center;
  background: #f07167;
  color: white;
  cursor: pointer;
}
.uploaddiv {
  margin-bottom: 20px;
}

@media only screen and (max-width: 400px) {
  .submit .btn {
    width: 30%;
    margin: 0 auto;
  }
}
.removearr
{
  float: right;
    margin-top: 25px;
    margin-right: 33px;
}
.removearr a{
  color: #f07167;
    cursor: pointer;
}