.containerHolder1 {
  width: 85%;
  margin: 0 auto;
  margin-top: 4em;
  font-family: "Poppins", sans-serif !important;
}

.containerHolder1 h1 {
  font-weight: 600;
}
.prevQues {
  color: #4bd3c5;
  cursor: pointer;
}
.loginmobile .react-tel-input .form-control {
  width: 100% !important;
  height: 45px !important;
}
.closeIcnOTP {
  position: absolute;
  right: 16px;
  width: 24px;
  cursor: pointer;
  margin-top: -35px;
}
.modal-content1 {
  background-color: #fefefe;
  margin: 8% auto;
  padding: 67px;
  border: 1px solid #888;
  width: 60%;
  border-radius: 20px;
  position: relative;
}
.accordionbg1 {
  position: relative;
  background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%);
  border-radius: 15px;
  padding-top: 23px;
  width: 100%;
  min-height: 48vh;
}

.accSummary1 {
  background: #fff;
  border-radius: 15px;
  box-shadow: 3px 4px 13px lightgrey;
  min-height: 48vh;
}

/* Login Mode css */

.loginMode {
  width: 75%;
  margin: 4em auto;
  box-shadow: 3px 4px 13px lightgrey;
  text-align: center;
  padding: 4em;
  border-radius: 20px;
}
.retrivaal_loginMode {
  width: 75%;
  margin: 4em auto;
  box-shadow: 3px 4px 13px lightgrey;
  text-align: center;
  padding: 4em;
  border-radius: 20px;
}
.Otp_loginMode {
  width: 50%;
  margin: 4em auto;
  box-shadow: 3px 4px 13px lightgrey;
  text-align: center;
  padding: 4em;
  border-radius: 20px;
  font-family: "Poppins", sans-serif !important;
}
.Otp_request_loginMode {
  width: 75%;
  margin: 4em auto;
  box-shadow: 3px 4px 13px lightgrey;
  text-align: center;
  padding: 4em;
  border-radius: 20px;
}

.loginSubmitHolder {
  width: 65%;
  margin: 0 auto;
}
.loginSubmit {
  width: 20%;
}
.loginSubmit1 {
  width: 100% !important;
}
.submitFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.widthadj {
  width: 200px;
}

.radiogrouper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 2em;
}

/* 
otp */

.resend {
  margin-top: 2em;
}

.resend > div {
  cursor: pointer;
}
.otpText {
  margin-top: 3em;
  width: 60%;
  margin-left: 20%;
}

.lockicon {
  width: 90px;
}
.mobile_form_control{
  margin-left: 50px !important;
}

@media only screen and (max-width: 400px) {
  .submit .btn {
    width: 50% !important;
    margin: 0 auto;
  }

  .containerHolder1 {
    margin-top: 0px !important;
  }
  .copyRight {
    margin-top: 20px;
    margin-left: 0px;
    text-align: center !important;
  }
  .loginSubmitHolder {
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
  .loginMode {
    width: 95%;
  }
  .retrivaal_loginMode,
  .Otp_request_loginMode {
    padding: 1em;
  }
  .Otp_loginMode {
    padding: 1em;
    width: 90%;
  }

  .LoginMethod {
    display: flex !important;
  }
  .copyRight {
    margin-top: 20px;
    margin-left: 0px;
    text-align: center !important;
  }
  .submit .btn {
    width: 50% !important;
    margin: 0 18px;
  }
  .submit .retrivalBtn {
    width: 60% !important;
    margin: 0 30px;
  }
  .submit .OtpBtn {
    width: 70% !important;
    margin: 0px 36px;
  }
  /* .viewer-layout-container {
    margin-left: 20px !important;
  } */
  .viewer-button {
    padding: 0px !important;
  }
  .viewer-toolbar-left {
    font-size: 10px !important;
  }
  .viewer-toolbar-right {
    font-size: 10px !important;
  }
  .viewer-toolbar-center {
    font-size: 10px !important;
  }
  .viewer-toolbar-current-page-input {
    width: 30px !important;
  }
  .pdfbtn {
    width: 23vw !important;
  }
  .loginBg {
    display: none;
  }
  .containerHolder1 {
    margin-top: 0px !important;
  }

  .radiogrouper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    margin-top: 2em;
  }

  .radiogrouper > div:nth-child(2) {
    margin-top: 1em;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .copyRight {
    margin-left: 100px;
    text-align: right;
  }

  .loginBg {
    display: none;
  }
  .containerHolder1 {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .loginBg {
    width: 88%;
  }
}

@media only screen and (max-width: 468px) {
  .retrivaal_loginMode {
    width: 90%;
    padding:1em !important;
  }
  .Otp_loginMode{
    width: 90%;
    padding:1em !important;

  }
  .Otp_request_loginMode{
    width: 90%;
    padding:1em !important;
  }
}



@media only screen and (max-width: 478px) {
  .email_form_control, .mobile_form_control{
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .mobile_form_control{
  margin-left: 0px !important;
 }


.email_form_control> .MuiTypography-body1 , .mobile_form_control> .MuiTypography-body1 {
  font-size: 12px !important;
}
}
