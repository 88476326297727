.footerHolder {
  height: 53.6px;
  max-height: 53.6px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 1em 2em;

  width: 100%;
  /* background-color: #00afb9; */
  color: white;
  text-align: center;
  align-items: center;

  /* box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.25); */
}
.footer_content {
  display: flex;
  color: #cecece;
}
.policy {
  padding-right: 40px;
}
.footerimgadj {
  height: 60px;
  vertical-align: middle;
}

.widthLog {
  /* width: 35px; */
  width: 26px;
}

.paddingADJF {
  margin-left: 10px;
  color: #000;
}

.birdlogo {
  color: #cecece;
}
.policy a {
  color: #cecece !important;
}
.policy a:hover {
  color: #cecece !important;
}
@media only screen and (max-width: 600px) {
  .footerHolder {
    display: block !important;
  }
  .birdlogo {
    display: block;
  }
  .footer_content {
    margin-top: 20px;
    display: block;
  }
  .policy {
    padding-top: 5px;
    padding-right: 0px !important;
  }
}
