html {
  font-size: 16px;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  outline: 0;
}
.MuiSelect-select.MuiSelect-select {
  font-family: "Poppins", sans-serif !important;
}
.MuiTypography-body1 {
  font-family: "Poppins", sans-serif !important;
}
.MuiFormLabel-root {
  font-family: "Poppins", sans-serif !important;
}
.MuiMenuItem-root {
  font-family: "Poppins", sans-serif !important;
}
input[type="text"] {
  color: gray !important;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-display: swap;
}
button {
  font-family: "Poppins", sans-serif !important;
}

.mainHolder {
  min-height: calc(100vh - 93.6px);
}

.MuiFormControl-root {
  width: 100% !important;
}

.btn {
  color: #fff;
  border-radius: 8px;
  background: #4bd3c5;
  border: unset;
  padding: 1em 2em;
  width: 100%;
  cursor: pointer;
}
.btn1 {
  color: #fff;
  border-radius: 8px;
  background: #4bd3c5;
  border: unset;
  padding: 1em 2em;
  width: 100%;
  cursor: pointer;
}
.btn3 {
  color: #fff;
  border-radius: 8px;
  background: #4bd3c5;
  border: unset;
  padding: 1em 2em;
  width: 100%;
  cursor: pointer;
}

.loginGrid {
  padding: 4em 2em;
}
.MuiOutlinedInput-input {
  padding: 13.5px 14px !important;
}
a:visited {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
}
button {
  cursor: pointer;
}
.loaderAdj {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}
.MuiCircularProgress-colorPrimary {
  color: #4ed3c5 !important;
}
.otpText input[type="text"] {
  text-align: center !important;
}

.wallet {
  width: 80%;
  margin:0 auto;
  display: flex !important;
  justify-content: flex-end;
}

.walletbtn {
  margin-top: 4px;
  background: #4bd3c5;
  border: none;
  padding: 5px;
  width: 10vw;
  border-radius: 10px;
  color: white;
  margin-right: 20px;
  font-size: 16px;
}
.walletbtn:focus {
  padding: 4px;
  border: 1px solid #349389;
}

.Reportbtn
{
  margin-top: 7px;
  background: #4bd3c5;
  border: none;
  padding: 5px;
  width: 12vw;
  border-radius: 10px;
  color: white;
  margin-right: 20px;
  font-size: 16px;
}

.pdfbody{
  width: 80% !important;
  margin:0 auto;
}

.btnbox {
  display: flex;
  justify-content: space-evenly;
}

.stateLoader{
	width: 50%;		
	display: flex;
	align-items: center;
	justify-content: center;
}

.stateLoader > .MuiCircularProgress-root {
	width: 28px !important;
	height: 28px !important;
}

.removeDose {
	display: flex;
	color: #FFF;
	justify-content: flex-end;
	font-size: 14px;
}


@media only screen and (max-width: 769px) {
  .wallet {
    margin-top: 60px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    margin-left: 40px;
  }
  .walletbtn {
    width: 35vw;
  }
  .Reportbtn
{
  width: 50vw;
}

  .viewer-layout-container,
  .viewer-layout-toolbar {
    overflow-x: auto;
  }
  .btnbox {
    width: 80%;
    margin:0 auto;
    justify-content: center !important;
  }
  .pdfbtn1 {
    width: 25vw !important;
  }

  .pdfbody{
    height: 600px;
    margin:0  auto !important;
  }
}

@media only screen and (max-width:1024px) {
  .removeDose{
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 376px) {

  .wallet {
    margin-left: 20px;
  }

.pdfbody{
    width: 90% !important;
  }
  .Reportbtn
  {
    width: 60vw;
  }
  .walletbtn.walltetbtn1 {
    width: 45vw !important;
  }
  .googleimg{
    width:250px;
  }

}
@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .walletbtn {
    width: 14vw;
  }
}