.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-left: 1em;
}

.headerlogo {
  width: 184px;
}
.headerlogo1 {
  width: 110px;
  margin-right: 1em;
}

.headertext {
  width: 193px;
}
