:root {
	--gray: gray;
	--btnBackground: #4bd3c5;
	--btn1Background: #f07167;
	--fontFamily: "Poppins", sans-serif;
}

.pl {
	padding-left: 20px !important;
}

.ml {
	margin-left: 20px !important;
}

.mt {
	margin-top: 15px !important;
}

.mb {
	margin-bottom: 20px !important;
}

.pt {
	padding-top: 15px !important;
}

.pr {
	padding-left: 20px !important;
}

.mr {
	margin-left: 20px !important;
}

.mx {
	margin-right: 15px !important;
	margin-left: 15px !important;
}

.my {
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}

.px {
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.py {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.d-flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.d-inline-flex {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.gray-text {
	color: var(--gray) !important;
}

.main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh;
	overflow-y: auto;
}

.form_container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.stepper_wrapper {
	margin-top: -10px;
	width: 60%;
	text-align: center;
}

.form_body {
	width: 70%;
	margin: 0 auto;
}

.form_back {
	position: relative;
	background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%);
	border-radius: 15px;
	padding-top: 15px;
	width: 100%;
	min-height: 48vh;
}

.form_overlay {
	background: #FFF;
	border-radius: 15px;
	box-shadow: 3px 4px 13px lightgrey;
	min-height: 48vh;
	margin-bottom: 20px;
}

.Infogrid {
	display: flex;
	justify-content: space-between;
}

.verifier_btn {
	margin: 0 12px;
	width: 140px;
	background-color: var(--btnBackground) !important;
	color: #fff !important;
	outline: none;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	line-height: 1.5;
	font-size: 16px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	padding: 0.75rem 1rem;
	border-radius: 0.9rem;
	font-family: var(--fontFamily) !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.verifier_btn1 {
	margin: 0 12px;
	width: 140px;
	background-color: var(--btn1Background) !important;
	color: #fff !important;
	outline: none;
	border: 0;
	display: inline-block;
	font-weight: bold;
	line-height: 1.5;
	font-size: 16px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	padding: 0.475rem 1rem;
	border-radius: 0.9rem;
	font-family: var(--fontFamily) !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.verifier_btn:hover,
.verifier_btn1:hover{
	filter: contrast(90%);
}

.verifier_btn:active,
.verifier_btn1:active{
	transform: translateY(0px) scale(0.98);
	filter: brightness(0.9);
}
.errorText {
	color: red !important;
	font-size: 12px;
}

.loaderAdj {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 70vh;
  }

.MuiCircularProgress-root{
	color:#4bd3c5 !important;
}

.MuiFormControl-root.form_textfield {
	width: 100% !important;
	font-family: "Poppins", sans-serif !important;
}

.form_textfield>.MuiInputLabel-root {
	color: gray !important;
	line-height: 1 !important;
}

.form_textfield>.MuiInputBase-root {
	color: gray !important;
	/* font-size: 15px; */
	/* font-weight: bold; */
}
.dob-dropdown{
	width: 150px !important;    
	margin-right: 10px !important;
}

.form-dropdown>.MuiInput-underline:after {
	border-bottom: 2px solid #00afb9 !important;
}

.form-dropdown>.MuiInput-root {  
        color: gray !important;
	/* font-size: 15px; */
	/* font-weight: bold; */
}

.form_textfield>.MuiInput-underline:after {
	border-bottom: 2px solid #00afb9 !important;
}

.form_textfield>.MuiFormLabel-root.Mui-focused {
	color: #00afb9 !important;
}

.form-dropdown>.MuiFormLabel-root.Mui-focused {
	color: #00afb9 !important;
}

.react-tel-input .special-label {
	display: none !important;
}

.react-tel-input .form-control {
	color: gray !important;
	/* font-weight: bold; */
	width: 100% !important;
	height: 45px !important;
}

.protonMail {
	text-decoration: none !important;
}

input[type="file"] {
	display: none;
}

.preview-img {
	width: 270px;
	height: 150px;
}

.preview-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-position: center;
}

.img-label {
	padding-top: 5px;
	font-size: 10px;
	color: gray;
}

.test_result{
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: space-evenly;  
        width: 450px;
        height: 30px;
        line-height: 150% !important;
        letter-spacing: 0.15px !important;
        color: gray !important;
}

.content_radio_btn > .MuiButtonBase-root.Mui-checked {
	color:#00afb9 !important;
  }
  .content_radio_btn > .MuiRadio-root{
      padding:9px !important;
  }

@media only screen and (max-width: 600px) {
	.form_body {
		width: 95%;
		margin: 0 10px 0 10px;
	}
	.stepper_wrapper {
		width: 80%;
	}
	.stepper_wrapper div,
	.stepper_wrapper a {
		font-size: 14px !important;
	}
	
	.form-dropdown {
		width: 100%;
	}
	.form-both-dose {
		display: flex;
		flex-direction: column !important;
	}
	
}


.testing-preview-img {
	width: 300px;
	height: 200px;
}

.testing-preview-img img {
	width: 100%;
	height: 100%;
	background-size: contain;	
	background-position: center;	
}